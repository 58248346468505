



export const MyFriendsNavList = [
    {
        navImg:require("@/views/Friend/Img/newFriends.png"),
        content:"新的好友",
        rightHide:true,
        path:"/newFriends"
    }
]
