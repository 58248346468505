import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store"
import MyFriendsStoreType from './indexType'
import { FriendsListType } from '@/Api/Message/indexType';

@Module({
    name:"MyFriends",
    store,
    dynamic: true,
})
export default class MyFriends extends VuexModule implements MyFriendsStoreType{

    MyFriendsUpData: FriendsListType = {
        pageNo: 1, pageSize: 15,
    };
    get getMyFriendsUpData(){ return this.MyFriendsUpData }
    @Mutation
    SetMyFriendsUpData(data: any): void {
        this.MyFriendsUpData = data
    }

    MyFriendsList: any[] = [];
    get getMyFriendsList(){ return this.MyFriendsList }
    @Mutation
    SetMyFriendsList(data: any[]): void {
        this.MyFriendsList = data
    }

}

export const MyFriendsStore = getModule( MyFriends )
