import { FriendsList } from '@/Api/Message';
import Storage from "@/util/Storage"
import { MyFriendsStore } from './Store';

// 我的好友 列表
export function getFriends() {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(FriendsList({ userId,token },MyFriendsStore.getMyFriendsUpData).then(res=>{
        return res.data;
    }))
}
